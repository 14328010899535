<template lang="pug">
.flex.flex-col.overflow-y-auto.w-full
  p.font-bold.text-darkblue.text-md.font-SourceSansPro {{$t("notifications.trips.mask")}} 
  .mt-3.relative.mb-8
    el-input.w-48(
      type='text',
      v-model='notification.trips_name_mask',
      :class='{ "input-error": validateOne }'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validateOne') {{$t("notifications.trips.validationOne")}} 
  p.font-bold.text-darkblue.text-md.font-SourceSansPro {{$t("notifications.trips.alertEvent")}} 
  skif-checkbox.mt-4(
    v-model='notification.is_start_trip',
    :label='$t("notifications.trips.start")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4(
    v-model='notification.is_end_trip',
     :label='$t("notifications.trips.end")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4(
    v-model='notification.is_in_trip_points',
     :label='$t("notifications.trips.departure")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4.ml-8(
    v-model='notification.is_in_tp_violation',
     :label='$t("notifications.trips.onlyViolation")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4(
    v-model='notification.is_out_trip_points',
    :label='$t("notifications.trips.arrCheckPoint")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4.ml-8(
    v-model='notification.is_out_tp_violation',
    :label='$t("notifications.trips.onlyViolation")',
    labelMargin='4',
    :error='validateTwo'
  )
  skif-checkbox.mt-4(
    v-model='notification.is_skip_trip_points',
    :label='$t("notifications.trips.pass")',
    labelMargin='4',
    :error='validateTwo'
  )
</template>

<script>
export default {
  props: {
    notification: Object,
    isTripsnamemask: Boolean,
    isOneCheckbox: Boolean,
  },
  data() {
    return {}
  },
  watch: {
    'notification.trips_name_mask': function (val) {
      this.validateOne = /^\s+$/.test(this.notification.trips_name_mask)
    },
    'notification.is_start_trip': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_end_trip': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_in_trip_points': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_in_tp_violation': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_out_trip_points': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_out_tp_violation': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
    'notification.is_skip_trip_points': function (val) {
      this.validateTwo = !(
        this.notification.is_start_trip ||
        this.notification.is_end_trip ||
        this.notification.is_in_trip_points ||
        this.notification.is_in_tp_violation ||
        this.notification.is_out_trip_points ||
        this.notification.is_out_tp_violation ||
        this.notification.is_skip_trip_points
      )
    },
  },
  computed: {
    validateOne: {
      get: function () {
        return this.isTripsnamemask
      },
      set: function (newValue) {
        this.$emit('update-tripsnamemask', newValue)
      },
    },
    validateTwo: {
      get: function () {
        return this.isOneCheckbox
      },
      set: function (newValue) {
        this.$emit('update-isonecheckbox', newValue)
      },
    },
  },
}
</script>
